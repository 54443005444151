import "../../functions";
import EchoService from "../../common/echo";
// https://github.com/fingerprintjs/fingerprintjs/blob/master/docs/api.md#webpackrollupnpmyarn
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const state = {
  logged: null,
  token: null,
  refreshToken: null,
  tokenExpiresIn: null,
  refreshingToken: false,
  initializing: false,
  user: null,
  userType: null,
  userpermissions: null,
};

const mutations = {
  setLogged(state, value) {
    state.logged = value;
  },
  setToken(state, token) {
    state.token = token;
  },
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  setTokenExpiresIn(state, tokenExpiresIn) {
    state.tokenExpiresIn = tokenExpiresIn;
  },
  setRefreshingToken(state, value) {
    state.refreshingToken = value;
  },
  setInitializing(state, value) {
    state.refreshingToken = value;
  },
  setUser(state, user) {
    state.user = user;
  },
  setUserType(state, usertype) {
    state.userType = usertype;
  },
  setUserPermissions(state, userpermissions) {
    state.userpermissions = userpermissions;
  },
  setLogout(state) {
    state.token = null;
    state.refreshToken = null;
    state.tokenExpiresIn = null;
    state.user = null;
    state.userType = null;
    state.userpermissions = null;
    state.logged = false;
  },
};

const actions = {
  async login({ commit, dispatch }, credentials) {
    try {
      let visitor_id = null;
      if ($cookies.get("visitor")) {
        visitor_id = $cookies.get("visitor").id;
      }
      const response = await axios.post("auth/site-user-login", {
        cpf: credentials.cpf,
        password: credentials.password,
        visitor_id: visitor_id,
      });
      const { access_token, refresh_token, expires_in } = response.data;

      commit("setToken", access_token);
      commit("setRefreshToken", refresh_token);
      commit("setTokenExpiresIn", expires_in);
      commit("setLogged", true);
      commit("setUserType", "siteuser");
      axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

      // Persistir o estado de autenticação no localStorage
      localStorage.setItem(
        "auth",
        JSON.stringify({
          token: access_token,
          refreshToken: refresh_token,
          tokenExpiresIn: expires_in,
          userType: "siteuser",
        })
      );

      await dispatch("fetchUser");
    } catch (error) {
      console.error("Login failed:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async fetchUser({ commit }) {
    try {
      const response = await axios.get("sitecpv/users/site-auth-user");
      commit("setUser", response.data.user);
      // commit("setUserPermissions", response.data.userPermissions);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      // localStorage.setItem("userPermissions", JSON.stringify(response.data.userPermissions));
    } catch (error) {
      console.error("Failed to fetch user:", error);
      // Se habilitar a linha abaixo entregará erro no front do site. Não queremos isso.
      // throw error; // Re-throw the error to handle it in the component
    }
  },
  async initialize({ commit, dispatch }) {
    let returnResult = null;
    if (!state.initializing) {
      console.info("Inicializando aplicação");
      commit("setInitializing", true);
      // Crio o cookie nulo para identificar o dispositivo
      if (!$cookies.isKey("visitor")) {
        $cookies.set("visitor", { id: null, token: null });
      }

      const fpPromise = FingerprintJS.load();
      // Get the visitor identifier when you need it.
      fpPromise
        .then((fp) => fp.get())
        .then((result) => {
          // Coloco o ID no cookie de identificação
          if ($cookies.get("visitor").id != result.visitorId) {
            $cookies.set("visitor", { id: result.visitorId, token: null });
          }
        })
        .then(async () => {
          console.log("visitorId: " + $cookies.get("visitor").id);

          const authData = JSON.parse(localStorage.getItem("auth"));
          if (!authData || !authData.token) {
            await axios
              .post(`auth/guest-token`, {
                device_id: $cookies.get("visitor").id,
              })
              .then((response) => {
                switch (response.status) {
                  case 200:
                    const { token } = response.data;
                    commit("setToken", token);
                    commit("setRefreshToken", null);
                    commit("setTokenExpiresIn", null);
                    commit("setLogged", false);
                    commit("setUserType", "visitor");
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

                    // Persistir o estado de autenticação no localStorage
                    localStorage.setItem(
                      "auth",
                      JSON.stringify({
                        token: token,
                        refreshToken: null,
                        tokenExpiresIn: null,
                        userType: "visitor",
                      })
                    );

                    console.log("Token Ok. Visitante");
                    // authToken = response.data.token;
                    $cookies.set("visitor", { id: $cookies.get("visitor").id, token: token });

                    break;

                  default:
                    console.log(response);
                    break;
                }
              });
          } else {
            commit("setToken", authData.token);
            commit("setRefreshToken", authData.refreshToken);
            commit("setTokenExpiresIn", authData.tokenExpiresIn);
            commit("setUserType", authData.userType);
            axios.defaults.headers.common["Authorization"] = `Bearer ${authData.token}`;
            if (authData.userType == "siteuser") {
              commit("setLogged", true);
              await dispatch("fetchUser");
            } else {
              commit("setLogged", false);
            }
          }

          // commit("setUser", JSON.parse(localStorage.getItem("user")));

          EchoService();

          commit("setInitializing", false);
        });
      return returnResult;
    }
  },

  async refreshToken({ commit, dispatch, state }) {
    console.log("Refreshing token");
    let returnResult = null;
    if (!state.refreshingToken) {
      commit("setRefreshingToken", true);
      try {
        let visitor_id = null;
        if ($cookies.get("visitor")) {
          visitor_id = $cookies.get("visitor").id;
        }
        if (state.refreshToken) {
          const response = await axios.post("auth/site-user-refresh", {
            refresh_token: state.refreshToken,
            device_id: visitor_id,
          });
          const { access_token, refresh_token, expires_in } = response.data;

          commit("setToken", access_token);
          commit("setRefreshToken", refresh_token);
          commit("setTokenExpiresIn", expires_in);
          commit("setLogged", true);
          commit("setUserType", "siteuser");
          axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

          // Persistir o estado de autenticação no localStorage
          localStorage.setItem(
            "auth",
            JSON.stringify({
              token: access_token,
              refreshToken: refresh_token,
              tokenExpiresIn: expires_in,
              userType: "siteuser",
            })
          );
          // Recarrego o Echo
          EchoService();
          returnResult = true;
        } else {
          console.log("Refresh token indisponível");
          commit("setLogout");
          delete axios.defaults.headers.common["Authorization"];
          localStorage.clear();
          // dispatch("initialize");
          await axios
            .post(`auth/guest-token`, {
              device_id: $cookies.get("visitor").id,
            })
            .then((response) => {
              switch (response.status) {
                case 200:
                  const { token } = response.data;
                  commit("setToken", token);
                  commit("setRefreshToken", null);
                  commit("setTokenExpiresIn", null);
                  commit("setLogged", false);
                  commit("setUserType", "visitor");
                  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

                  // Persistir o estado de autenticação no localStorage
                  localStorage.setItem(
                    "auth",
                    JSON.stringify({
                      token: token,
                      refreshToken: null,
                      tokenExpiresIn: null,
                      userType: "visitor",
                    })
                  );

                  console.log("Token Ok. Visitante");
                  EchoService();
                  $cookies.set("visitor", { id: $cookies.get("visitor").id, token: token });

                  break;

                default:
                  console.log(response);
                  break;
              }
            });
          // ---- // dispatch("initialize");
          returnResult = false;
        }

        commit("setRefreshingToken", false);
      } catch (error) {
        // console.error("Failed to refresh token:", error);
        // ----------------

        console.log("Refresh token indisponível");
        commit("setLogout");
        delete axios.defaults.headers.common["Authorization"];
        localStorage.clear();
        // dispatch("initialize");
        await axios
          .post(`auth/guest-token`, {
            device_id: $cookies.get("visitor").id,
          })
          .then((response) => {
            switch (response.status) {
              case 200:
                const { token } = response.data;
                commit("setToken", token);
                commit("setRefreshToken", null);
                commit("setTokenExpiresIn", null);
                commit("setLogged", false);
                commit("setUserType", "visitor");
                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

                // Persistir o estado de autenticação no localStorage
                localStorage.setItem(
                  "auth",
                  JSON.stringify({
                    token: token,
                    refreshToken: null,
                    tokenExpiresIn: null,
                    userType: "visitor",
                  })
                );

                console.log("Token Ok. Visitante");
                EchoService();
                $cookies.set("visitor", { id: $cookies.get("visitor").id, token: token });

                break;

              default:
                console.log(response);
                break;
            }
          });

        // throw error; // Re-throw the error to handle it in the component

        returnResult = false;
        commit("setRefreshingToken", false);

        // ----------------
      }
    } else {
      console.log("Already refreshing token");
      returnResult = false;
    }

    return returnResult;
  },
  async logout({ commit }) {
    let deviceId = null;
    if ($cookies.get("visitor")) {
      deviceId = $cookies.get("visitor").id;
    }
    try {
      await axios.get("sitecpv/users/logout/" + deviceId);
      commit("setLogout");
      delete axios.defaults.headers.common["Authorization"];
      localStorage.clear();
      window.location.href = "/";
    } catch (error) {
      console.log(error);
    }
  },
  async frontLogout({ commit }) {
    // const environment = Vue.prototype.$detectEnvironment();
    // if (!environment.isMobile) {
    //   await NotifyService.destroy();
    // }
    commit("setLogout");
    delete axios.defaults.headers.common["Authorization"];
    localStorage.clear();
    window.location.href = "/login";
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
