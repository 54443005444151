import axios from "axios";
import store from "../store";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Authorization"] = `Bearer ${store.state.auth.token}`;
// axios.defaults.headers.common["Cache-Control"] = "no-cache";
// axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config) => {
    const privateUrls = [];
    const authRequired = privateUrls.includes(config.url);
    if (authRequired) {
      const authData = JSON.parse(localStorage.getItem("auth"));
      if (!authData || !authData.token) {
        console.log("Auth required. Request fail. Front Logout");
        await store.dispatch("auth/frontLogout");
        return Promise.reject();
        // return Promise.reject(new Error("Authorization header is missing!"));
      }
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    const status = response ? response.status : null;

    // Tratamento específico para erros de timeout
    if (
      error.code === "ECONNABORTED" ||
      error.code === "ERR_NETWORK" ||
      error.message.includes("timeout") ||
      error.message.includes("Network Error")
    ) {
      console.error("Network timeout error:", error);
      // alert("A conexão com a internet foi perdida. Por favor, verifique sua conexão.");
      // Opcional: tentar refazer a requisição ou tomar outra ação após um tempo
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(axios(config));
        }, 5000); // Tentando refazer a requisição após 5 segundos
      });
    }

    // Tratamento de erro 401 e refresh token
    // Não ativo caso esteja na tela de login
    if (status === 401 && !config._retry && !response.request.responseURL.includes("site-user-login")) {
      config._retry = true;
      try {
        // Tenta obter um novo token de acesso usando o refresh token
        await store.dispatch("auth/refreshToken");

        // Atualiza o cabeçalho de autorização da requisição original com o novo token de acesso
        // Comentado pois estou fazendo isso na função refreshToken.
        // config.headers["Authorization"] = `Bearer ${store.state.auth.token}`;

        // Tenta refazer a requisição original com o novo token de acesso
        return axios(config);
      } catch (refreshError) {
        // Se a obtenção do refresh token falhar, desloga o usuário e rejeita a promessa com o erro
        // console.log(refreshError);
        // return;
        // console.log("Refresh token fail. Front Logout");
        // await store.dispatch("auth/frontLogout");
        return Promise.reject(refreshError);
      }
    }

    if (status === 422) {
      return Promise.resolve(response);
    }
    // Rejeita a promessa com o erro original se não for um erro 401 ou se já foi marcada como retry
    return Promise.reject(error);
  }
);
