var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-menu",class:{ is_visible: _vm.isVisible },attrs:{"fixed":"bottom"}},[_c('div',{staticClass:"d-desktops mt-2"},[_c('div',{staticClass:"clearfix hidde__default"},[_c('div',{staticClass:"float-left"},[_c('h4',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.curso.nome))])])]),_c('div',{staticClass:"clearfix d-none d-md-block d-lg-block"},[_c('div',{staticClass:"float-left hidde__default"},[_c('div',{staticClass:"info__hours__unitys mt-2"},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"float-left mr-3 border-right pt-2 pb-2 pr-3"},[_c('i',{staticClass:"fa-regular fa-clock mr-1"}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.curso.horario))])]),_c('div',{staticClass:"float-right mr-3 border-right pt-2 pb-2 pr-3"},[_c('i',{staticClass:"fa-regular fa-location-dot mr-1"}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.curso.unidades))])])])])]),_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"float-left"},[_c('div',{staticClass:"info__values d-inline-flex"},[(_vm.curso.valor_total_presencial !== '')?_c('div',{staticClass:"mr-4"},[(_vm.curso.oferta_presencial &&
                  _vm.curso.oferta_presencial.valor_total_oferta < _vm.curso.valor_total_presencial)?_c('div',[_vm._m(0),_c('span',{class:_vm.curso.oferta_presencial &&
                    _vm.curso.oferta_presencial.valor_total_oferta ? 'preco-normal' : null},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.valor_total_presencial))+" ")])]),_vm._v("   "),_c('span',[_c('span',{staticClass:"font-weight-bolder preco-oferta"},[_vm._v(" R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.oferta_presencial.valor_total_oferta))+" ")])])]):_c('div',[_vm._m(1),_c('span',{staticClass:"font-weight-bolder value"},[_vm._v("R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.valor_total_presencial)))])])]):_vm._e(),(_vm.curso.valor_total_ead !== '')?_c('div',{staticClass:"ml-3"},[(_vm.curso.oferta_ead &&
                  _vm.curso.oferta_ead.valor_total_oferta < _vm.curso.valor_total_ead)?_c('div',[_vm._m(2),_c('span',{class:_vm.curso.oferta_ead &&
                    _vm.curso.oferta_ead.valor_total_oferta ? 'preco-normal' : null},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.valor_total_ead))+" ")])]),_vm._v("   "),_c('span',[_c('span',{staticClass:"font-weight-bolder preco-oferta"},[_vm._v(" R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.oferta_ead.valor_total_oferta))+" ")])])]):_c('div',[_vm._m(3),_c('span',{staticClass:"font-weight-bolder value"},[_vm._v("R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.valor_total_ead)))])])]):_vm._e()])]),_c('div',{staticClass:"float-right ml-2 mr-2"},[_c('b-link',{staticClass:"btn btn__default d-none d-block",attrs:{"to":{ name: 'matricula', params: { slug: _vm.slug, tabelaPrecosId: _vm.curso.tabela_precos_id } }}},[_vm._v("Matricule-se")])],1)])])]),_c('div',{staticClass:"d-mobile d-md-none pl-3 pr-3"},[_c('div',{staticClass:"clearfix d-flex justify-content-center"},[_c('div',{staticClass:"float-left d-inline-flex"},[(_vm.curso.valor_total_presencial !== '')?_c('div',[(_vm.curso.oferta_presencial &&
              _vm.curso.oferta_presencial.valor_total_oferta < _vm.curso.valor_total_presencial)?_c('div',[_vm._m(4),_c('span',{class:_vm.curso.oferta_presencial &&
                _vm.curso.oferta_presencial.valor_total_oferta ? 'preco-normal' : null},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.valor_total_presencial))+" ")])]),_vm._v("   "),_c('span',[_c('span',{staticClass:"font-weight-bolder preco-oferta"},[_vm._v(" R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.oferta_presencial.valor_total_oferta))+" ")])])]):_c('div',{staticClass:"value_total"},[_vm._m(5),_c('span',{staticClass:"font-weight-bolder value"},[_vm._v(_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.valor_total_presencial)))])])]):_vm._e(),(_vm.curso.valor_total_ead !== '')?_c('div',{staticClass:"ml-3"},[(_vm.curso.oferta_ead &&
              _vm.curso.oferta_ead.valor_total_oferta < _vm.curso.valor_total_ead)?_c('div',[_vm._m(6),_c('span',{class:_vm.curso.oferta_ead &&
                _vm.curso.oferta_ead.valor_total_oferta ? 'preco-normal' : null},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.valor_total_ead))+" ")])]),_vm._v("   "),_c('span',[_c('span',{staticClass:"font-weight-bolder preco-oferta"},[_vm._v(" R$ "+_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.oferta_ead.valor_total_oferta))+" ")])])]):_c('div',{staticClass:"value_total"},[_vm._m(7),_c('span',{staticClass:"font-weight-bolder value"},[_c('span',[_vm._v("R$")]),_vm._v(_vm._s(_vm._f("formatDecimalNumber")(_vm.curso.valor_total_ead)))])])]):_vm._e()])]),_c('b-link',{staticClass:"btn btn__default d-block d-md-none",attrs:{"to":{ name: 'matricula', params: { slug: _vm.slug, tabelaPrecosId: _vm.curso.tabela_precos_id } }}},[_vm._v("Matricule-se")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('h6',{staticClass:"font-weight-lighter text-black-50 title_value"},[_vm._v("Presencial")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-end"},[_c('h6',{staticClass:"font-weight-lighter text-black-50 title_value"},[_vm._v("Presencial")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('h6',{staticClass:"font-weight-lighter text-black-50 title_value"},[_vm._v("EaD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-end"},[_c('h6',{staticClass:"font-weight-lighter text-black-50 title_value"},[_vm._v("EaD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('h6',{staticClass:"font-weight-lighter text-black-50 title_value"},[_vm._v("Presencial")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-end"},[_c('h6',{staticClass:"font-weight-lighter text-black-50 title_value"},[_vm._v("Presencial")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('h6',{staticClass:"font-weight-lighter text-black-50 title_value"},[_vm._v("EaD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-end"},[_c('h6',{staticClass:"font-weight-lighter text-black-50 title_value"},[_vm._v("EaD")])])
}]

export { render, staticRenderFns }