import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "../store";
import auth from "./auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...routes, ...auth],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const authPages = ["login", "register", "forgot-password", "reset-password"];
  const openPages = !authPages.includes(to.name);

  // scroll to top when changing pages
  if (document.scrollingElement) {
    document.scrollingElement.scrollTop = 0;
  } else if (document.documentElement) {
    document.documentElement.scrollTop = 0;
  }

  if (openPages) {
    store.commit("setLayout", "site");
  } else {
    store.commit("setLayout", "auth");
  }

  // if (to.name == "matricula") {
  //   $cookies.set("destino_matricula", { slug: to.params.slug, tabelaPrecosId: to.params.tabelaPrecosId });
  //   const authData = JSON.parse(localStorage.getItem("auth"));
  //   if (authData && authData.token && authData.userType == "siteuser") {
  //   } else {
  //     next({ name: "login" });
  //   }
  // }

  next();
});

export default router;
