import Vue from "vue";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import store from "../store";

// Initialize an agent at application startup.

window.Pusher = Pusher;

const EchoService = (retryCount = 0) => {
  try {
    if (retryCount > 5) {
      console.error("Max retry attempts reached.");
      return;
    }
    retryCount++;
    console.log(`Attempting to join channel... (retry count: ${retryCount} / 5)`);

    var serverURL = process.env.VUE_APP_SERVER + "api/visitor-broadcasting/auth";
    var key = process.env.VUE_APP_PUSHER_KEY;

    const authData = JSON.parse(localStorage.getItem("auth"));

    if (authData.token) {
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: key,
        cluster: "mt1",
        forceTLS: true,
        authEndpoint: serverURL,
        auth: {
          headers: {
            Authorization: "Bearer " + authData.token,
            "User-Type": authData.userType,
          },
        },
      });

      // Capturando erros de autenticação e conexão
      window.Echo.connector.pusher.connection.bind("error", function (error) {
        if (error.error) {
          if (error.error.data) {
            console.log("Pusher error:", error.error.data);
          } else {
            console.log("Pusher error:", error);
          }
        } else {
          console.log("Pusher error:", error);
        }
      });

      // Capturando erros de autenticação específicos do Laravel Echo
      window.Echo.connector.pusher.connection.bind("pusher:subscription_error", function (status) {
        console.log("Subscription error:", status);
      });

      // Capturando desconexões
      window.Echo.connector.pusher.connection.bind("disconnected", function () {
        console.log("Pusher disconnected");
      });

      // Capturando reconexões
      window.Echo.connector.pusher.connection.bind("connected", function () {
        console.log("Pusher connected");
        // Pusher conectado. Agora vou entrar no canal principal do site.
        Vue.prototype.$joinChannel("main");
      });

      // Capturando tentativa de reconexão
      window.Echo.connector.pusher.connection.bind("connecting_in", function (delay) {
        console.log("Pusher attempting to reconnect in", delay, "seconds");
      });
    }
  } catch (error) {
    console.log("EchoService error:", error);
  }
};

export default EchoService;
