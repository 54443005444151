import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    layout: "site",
  },
  mutations: {
    setLayout(state, payload) {
      // console.log("setLayout", payload);
      state.layout = payload;
    },
  },
  getters: {
    layout(state) {
      return state.layout;
    },
  },
  actions: {},
  modules: { auth },
});
