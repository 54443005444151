<template>
  <b-breadcrumb class="bg-light breadcrumb__cpv">
    <b-breadcrumb-item class="link__breadcrumb" :to="{ name: 'cursinho' }">
      <span class="font-weight-bolder text-uppercase">{{ mainSite }}</span>
    </b-breadcrumb-item>
    <b-breadcrumb-item class="link__breadcrumb" 
      v-if="
        this.$route.meta.section_childname == 'proximasturmas' && this.$route.name !== 'proximasturmas' ||
        this.$route.meta.section_childname == 'cpv-resolve' && this.$route.name !== 'cpv-resolve'
        "
      :to="{ name: 
        this.$route.meta.section_childname == 'proximasturmas' 
        ? 'proximasturmas' 
        : this.$route.meta.section_childname == 'cpv-resolve' 
        ? 'cpv-resolve'
        : ''
      }">
        <span class="font-weight-bolder text-uppercase">{{ singlePage }}</span>
    </b-breadcrumb-item>
    
    <b-breadcrumb-item  class="link__breadcrumb font-weight-bolder text-uppercase"
      v-if="this.$route.meta.section_thirdname == 'matricula'"
      :to="{ name: 'detalhe-do-curso', params: { slug: this.$route.params.slug }}"
      :active="this.$route.meta.section_thirdname == 'detalhe-do-curso'"
    >
      <strong class="text-uppercase">{{ pageCourse }}</strong>
    </b-breadcrumb-item>
    <b-breadcrumb-item 
      v-if="this.$route.meta.section_thirdname == 'matricula'"
      :active="true"
    >
      <strong class="text-uppercase">{{ pageMatriculaCourse }}</strong>
    </b-breadcrumb-item>
    <b-breadcrumb-item class="link__breadcrumb text-uppercase" active  aria-current="page" v-if="this.$route.name !== 'matricula'">{{ $route.meta.title || title }} </b-breadcrumb-item>
  </b-breadcrumb>


    <!-- <b-breadcrumb-item class="link__breadcrumb"
      v-if="this.$route.name === 'vestibular-resolvido' && this.$route.name !== 'cpv-resolve'"
      :to="{ name: 'cpv-resolve' }"
    >
      <strong class="text-uppercase">888</strong>
    </b-breadcrumb-item> -->
</template>

<script>
export default {
  name: 'BreadCpv',
  props: [ "title", "url", "slug" ],
  data() {
    return {}
  },
  computed: {
    mainSite: function () {
      if (this.$route.meta.section_name == 'cursinho') return 'Cursinho'
    },

    singlePage: function () {
      if (this.$route.meta.section_childname == 'proximasturmas') {
        return 'Próximas Turmas'
      } else if (this.$route.meta.section_childname == 'cpv-resolve') {
        return 'CPV Resolve'
      }
    },
    pageCourse: function () {
      const pathMap = {

        /* TURMAS DE EXTENSIVO */
        'extensivo': 'Extensivo',
        'extensivo-medicina-e-exatas': 'Extensivo Medicina e Exatas',
        'extensivo-fgv-direito-e-ri': 'Extensivo + FGV Direito e RI',
        'extensivo-fgv-economia': 'Extensivo + FGV Economia',

        /* TURMAS DE 1º SEMESTRE */
        'semi': 'Semi - Manhã',
        'semi-fgv-e-insper-manha': 'Semi FGV/Insper - Manhã',
        'intensivo-fgv-e-insper': 'Intensivo FGV/Insper',
        'preparacao-para-a-jornada-link': 'Preparação Jornada Link',
        'revisao-fgv-e-insper': 'Revisão FGV/Insper',
        
        /* TURMAS DE 2º SEMESTRE */
        'semi-fgv-insper-manha': 'Semi FGV/Insper - Manhã',
        'semi-fgv-e-insper-tarde': 'Semi FGV/Insper - Tarde',
        'semi-tarde': 'Semi - Tarde',
        'intensivo-einstein-vunesp': 'Intensivo Einstein (Vunesp)',
        'intensivo-fgv-insper-tarde': 'Intensivo FGV/Insper - Tarde',
        'intensivo-fuvest': 'Intensivo Fuvest',
        'preparacao-para-jornada-link': 'Preparação Jornada Link',
        'revisao-fgv-insper-tarde': 'Revisão FGV/Insper',
        'revisao-fuvest-1a-fase-manha': 'Revisão Fuvest 1ª Fase - Manhã',
        'revisao-medicina-fmabc': 'Revisão Medicina FMABC',
        'revisao-insper-manha': 'Revisão Insper - Manhã',
        'revisao-fuvest-2a-fase-manha': 'Revisão Fuvest 2ª Fase - Manhã',
        'revisao-insper-tarde': 'Revisão Insper - Tarde',
        'preparacao-2a-fase-einstein-mme': 'Preparação 2ª Fase Einstein - MME'        
      }
      return pathMap[this.$route.params.slug]
    },
    pageMatriculaCourse: function () {
      const matriculaPathPattern = /^\/proximasturmas\/matricula\/\d+\/[\w-]+\/?$/

      if(matriculaPathPattern.test(this.$route.path)) {
        return `Formulário de Matrícula`
      }
    }
  }

}
</script>

<style scoped>
.active {
  font-weight:300;
}

.breadcrumb__cpv {
  font-family: "Clan-Book Book", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  
}

.breadcrumb__cpv .link__breadcrumb {
  text-decoration: none !important;
}
.breadcrumb__cpv .link__breadcrumb span {
  color: #888888;
}
.breadcrumb__cpv .link__breadcrumb:hover {
  text-decoration: none !important;
}

</style>