<template>
  <div>
    <cpv-header-auth></cpv-header-auth>
    <div id="content" class="main-content">
      <router-view></router-view>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import cpvHeaderAuth from "@/components/global/header-auth.vue";

export default {
  components: {
    cpvHeaderAuth,
  },
};
</script>

<style scoped>
.main-content {
  font-family: "Clan-Book Book", "FF Clan Pro", sans-serif !important;
}
.viewFooter {
  display: block;
}
.hideFooter {
  display: none;
}
@media (min-width: 1200px) {
  .main-content {
    margin-top: 95px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .main-content {
    margin-top: 95px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .main-content {
    margin-top: 70px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .main-content {
    margin-top: 70px;
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .main-content {
    margin-top: 70px;
  }
}
</style>
