const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/auth/login.vue"),
    meta: { section_name: "cursinho", section_childname: "login", title: "Login" },
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: "register" */ "../views/auth/register.vue"),
    meta: { section_name: "cursinho", section_childname: "register", title: "Cadastro" },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import(/* webpackChunkName: "forgot-password" */ "../views/auth/forgot-password.vue"),
    meta: { section_name: "cursinho", section_childname: "forgot-password", title: "Recuperação de Senha" },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: () => import(/* webpackChunkName: "reset-password" */ "../views/auth/reset-password.vue"),
    props: (route) => {
      return {
        ...route.params,
        ...{ token: route.params.token },
      };
    },
    meta: { section_name: "cursinho", section_childname: "reset-password", title: "Recuperação de Senha" },
  },
];
export default routes;
