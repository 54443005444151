import Vue from "vue";
import store from "./store";
import moment from "moment";
moment.locale("pt-Br");

Vue.prototype.$checkRole = function (value) {
  if (store.state.auth.user) {
    if (store.state.auth.user.role == value) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

Vue.prototype.$checkPermission = function (value) {
  if (store.state.auth.userpermissions) {
    if (store.state.auth.userpermissions.includes(value)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

Vue.prototype.$detectEnvironment = function () {
  const userAgent = navigator.userAgent;
  const isMobileDevice = /android|iphone|ipad|ipod|opera mini|iemobile|mobile/i.test(userAgent);
  let browser;

  if (/chrome|crios|crmo/i.test(userAgent) && !/edge|edg|opera|opr|OPR/i.test(userAgent)) {
    browser = "Chrome";
  } else if (/safari/i.test(userAgent) && !/chrome|crios|crmo/i.test(userAgent)) {
    browser = "Safari";
  } else if (/firefox|fxios/i.test(userAgent)) {
    browser = "Firefox";
  } else if (/edg/i.test(userAgent)) {
    browser = "Edge";
  } else if (/opr|opera/i.test(userAgent)) {
    browser = "Opera";
  } else {
    browser = "Unknown";
  }

  return {
    isMobile: isMobileDevice,
    browser: browser,
  };
};

Vue.prototype.$waitForUser = function (timeout = 10000) {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();
    let echoTimeout = null;
    const checkUser = () => {
      if (store.state) {
        if (store.state.auth) {
          if (store.state.auth.user) {
            resolve();
            return;
          }
        }
      }

      if (Date.now() - startTime >= timeout) {
        reject(new Error("Timeout ao localizar o usuário logado"));
        return;
      }

      echoTimeout = setTimeout(checkUser, 1000); // Verifica novamente em 100ms
    };
    checkUser();
  });
};

Vue.prototype.$waitForEcho = function (timeout = 10000) {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();
    let echoTimeout = null;
    const checkEcho = () => {
      if (window.Echo && window.Echo.connector && window.Echo.connector.pusher) {
        if (window.Echo.connector.pusher.connection.state === "connected") {
          // console.log("Echo conectado");
          resolve();
          return;
        }
      }

      if (Date.now() - startTime >= timeout) {
        reject(new Error("Timeout ao tentar conectar ao Echo"));
        return;
      }

      echoTimeout = setTimeout(checkEcho, 1000); // Verifica novamente em 100ms
    };
    checkEcho();
  });
};

Vue.prototype.$joinChannel = function (channelName) {
  const joinCursinhoChannel = async (channelName) => {
    if (window.Echo) {
      console.info(`Tentando entrar no canal Cursinho ${channelName}`);
      return new Promise((resolve, reject) => {
        window.Echo.join(`cursinho.cpv.${channelName}`)
          .here((users) => {
            console.info(`Entrou no canal Cursinho ${channelName}`);
            resolve(users); // Resolve a Promise quando entra no canal
          })
          .error(async (error) => {
            console.info(`Erro ${error.status} ao entrar no canal Cursinho ${channelName}`);
            if (error.status === 401) {
              try {
                // Tenta obter um novo token de acesso usando o refresh token
                await store.dispatch("auth/refreshToken");
                console.info("Tentando novamente entrar no canal");

                // Aqui você pode chamar a função novamente, mas precisa esperar a resolução
                const result = await joinCursinhoChannel(channelName);
                resolve(result); // Resolve a Promise com o resultado da nova tentativa
              } catch (refreshError) {
                console.error("Erro ao obter novo token de acesso:", refreshError);
                reject(refreshError); // Rejeita a Promise em caso de erro
              }
            } else {
              reject(error); // Rejeita a Promise para outros erros
            }
          });
      });
    }
  };

  // Chame a função para entrar no canal
  (async () => {
    try {
      await joinCursinhoChannel(channelName);
    } catch (error) {
      console.error("Falha ao entrar no canal:", error);
    }
  })();
};

Vue.prototype.$leaveChannel = function (channelName) {
  const leaveCursinhoChannel = async (channelName) => {
    if (window.Echo && window.Echo.connector && window.Echo.connector.pusher) {
      if (window.Echo.connector.pusher.connection.state === "connected") {
        window.Echo.leave(`cursinho.cpv.${channelName}`);
        console.info(`Saiu do canal Cursinho ${channelName}`);
      }
    }
  };

  // Chame a função para entrar no canal
  (async () => {
    try {
      await leaveCursinhoChannel(channelName);
    } catch (error) {
      console.error("Falha ao sair no canal:", error);
    }
  })();
};
