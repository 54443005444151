<template>
  <div class="panel-menu" fixed="bottom" :class="{ is_visible: isVisible }">
    <div class="d-desktops mt-2">
      <!-- TÍTULO DO CURSO NO PANEL -->
      <div class="clearfix hidde__default">
        <div class="float-left">
          <h4 class="font-weight-bolder">{{ curso.nome }}</h4>
        </div>
      </div>

      <div class="clearfix d-none d-md-block d-lg-block ">
        <!-- INFO HORÁRIO E UNIDADES -->
        <div class="float-left hidde__default">
          <div class="info__hours__unitys mt-2">
            <div class="clearfix">
              <div class="float-left mr-3 border-right pt-2 pb-2 pr-3">
                <i class="fa-regular fa-clock mr-1"></i>
                <span class="font-weight-bolder">{{ curso.horario }}</span>
              </div>
              <div class="float-right mr-3 border-right pt-2 pb-2 pr-3">
                <i class="fa-regular fa-location-dot mr-1"></i>
                <span class="font-weight-bolder">{{ curso.unidades }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- INFO VALOR PRESENCIAL & EAD E BOTÃO MATRICULA -->
        <div class="float-right">
          <div class="clearfix">
            <div class="float-left">
              <div class="info__values d-inline-flex">
                <div v-if="curso.valor_total_presencial !== ''" class="mr-4">
                  <div v-if="curso.oferta_presencial &&
                    curso.oferta_presencial.valor_total_oferta < curso.valor_total_presencial">
                    <div class="d-flex justify-content-center">
                      <h6 class="font-weight-lighter text-black-50 title_value">Presencial</h6>
                    </div>
                    <span :class="curso.oferta_presencial &&
                      curso.oferta_presencial.valor_total_oferta ? 'preco-normal' : null">
                      <span class="font-weight-bolder">
                        R$ {{ curso.valor_total_presencial | formatDecimalNumber }}
                      </span>
                    </span>
                    &nbsp;
                    <span>
                      <span class="font-weight-bolder preco-oferta">
                        R$ {{ curso.oferta_presencial.valor_total_oferta | formatDecimalNumber }}
                      </span>
                    </span>
                  </div>
                  <div v-else>
                    <div class="d-flex justify-content-end">
                      <h6 class="font-weight-lighter text-black-50 title_value">Presencial</h6>
                    </div>
                    <span class="font-weight-bolder value">R$ {{ curso.valor_total_presencial | formatDecimalNumber }}</span>
                  </div>
                </div>
                <div class="ml-3" v-if="curso.valor_total_ead !== ''">
                  <div v-if="curso.oferta_ead &&
                    curso.oferta_ead.valor_total_oferta < curso.valor_total_ead">
                    <div class="d-flex justify-content-center">
                      <h6 class="font-weight-lighter text-black-50 title_value">EaD</h6>
                    </div>
                    <span :class="curso.oferta_ead &&
                      curso.oferta_ead.valor_total_oferta ? 'preco-normal' : null">
                      <span class="font-weight-bolder">
                        R$ {{ curso.valor_total_ead | formatDecimalNumber }}
                      </span>
                    </span>
                    &nbsp;
                    <span>
                      <span class="font-weight-bolder preco-oferta">
                        R$ {{ curso.oferta_ead.valor_total_oferta | formatDecimalNumber }}
                      </span>
                    </span>
                  </div>
                  <div v-else>
                    <div class="d-flex justify-content-end">
                      <h6 class="font-weight-lighter text-black-50 title_value">EaD</h6>
                    </div>
                    <span class="font-weight-bolder value">R$ {{ curso.valor_total_ead | formatDecimalNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Botão Matricule-se -->
            <div class="float-right ml-2 mr-2">
              <b-link class="btn btn__default d-none d-block"
                :to="{ name: 'matricula', params: { slug: slug, tabelaPrecosId: curso.tabela_precos_id } }">Matricule-se</b-link>
            </div>
          </div>
        </div>
      </div>

      <!-- For Mobile Display -->
      <div class="d-mobile d-md-none pl-3 pr-3">
        <div class="clearfix d-flex justify-content-center">
          <div class="float-left d-inline-flex">
            <div v-if="curso.valor_total_presencial !== ''">
              <div v-if="curso.oferta_presencial &&
                curso.oferta_presencial.valor_total_oferta < curso.valor_total_presencial">
                <div class="d-flex justify-content-center">
                  <h6 class="font-weight-lighter text-black-50 title_value">Presencial</h6>
                </div>
                <span :class="curso.oferta_presencial &&
                  curso.oferta_presencial.valor_total_oferta ? 'preco-normal' : null">
                  <span class="font-weight-bolder">
                    R$ {{ curso.valor_total_presencial | formatDecimalNumber }}
                  </span>
                </span>
                &nbsp;
                <span>
                  <span class="font-weight-bolder preco-oferta">
                    R$ {{ curso.oferta_presencial.valor_total_oferta | formatDecimalNumber }}
                  </span>
                </span>
              </div>
              <div v-else class="value_total">
                <div class="d-flex justify-content-end">
                  <h6 class="font-weight-lighter text-black-50 title_value">Presencial</h6>
                </div>
                <span class="font-weight-bolder value">{{ curso.valor_total_presencial | formatDecimalNumber }}</span>
              </div>
            </div>
            <div class="ml-3" v-if="curso.valor_total_ead !== ''">
              <div v-if="curso.oferta_ead &&
                curso.oferta_ead.valor_total_oferta < curso.valor_total_ead">
                <div class="d-flex justify-content-center">
                  <h6 class="font-weight-lighter text-black-50 title_value">EaD</h6>
                </div>
                <span :class="curso.oferta_ead &&
                  curso.oferta_ead.valor_total_oferta ? 'preco-normal' : null">
                  <span class="font-weight-bolder">
                    R$ {{ curso.valor_total_ead | formatDecimalNumber }}
                  </span>
                </span>
                &nbsp;
                <span>
                  <span class="font-weight-bolder preco-oferta">
                    R$ {{ curso.oferta_ead.valor_total_oferta | formatDecimalNumber }}
                  </span>
                </span>
              </div>
              <div v-else class="value_total">
                <div class="d-flex justify-content-end">
                  <h6 class="font-weight-lighter text-black-50 title_value">EaD</h6>
                </div>
                <span class="font-weight-bolder value"><span>R$</span>{{ curso.valor_total_ead | formatDecimalNumber }}</span>
              </div>
            </div>
          </div>
        </div>
        <b-link class="btn btn__default d-block d-md-none"
          :to="{ name: 'matricula', params: { slug: slug, tabelaPrecosId: curso.tabela_precos_id } }">Matricule-se</b-link>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: ['curso', 'slug'],
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (window.scrollY >= 350) {
        this.isVisible = true
      } else {
        this.isVisible = false
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Clan-Book Book", sans-serif;
}

span {
  font-family: "FF Clan Pro", sans-serif;
}

.d-desktops {
  width: 92%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.panel-menu {
  font-family: "Clan-Book Book", "FF Clan Pro" sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 0px;
  background-color: #ffffff;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.29);
  z-index: 9999;
  -webkit-transform: translateY(0px);
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  height: auto;
}

.panel-menu.is_visible {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.info__hours__unitys i {
  color: #ef4857;
}
.info__hours__unitys span {
  font-size: 14px;
}


/* .btn__default {
  /* -webkit-transform: translateY(0);
} */

.btn__default {
  display: inline-block;
  min-width: 135px;
  margin-bottom: 0;
  font-weight: bold;

  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-image: none;
  padding: 15px 25px;
  background: linear-gradient(90deg,
      rgb(239, 50, 66) 0%,
      rgb(239, 50, 66) 50%,
      rgb(239, 72, 87) 50%,
      rgb(239, 72, 87) 100%);
  background-size: 200% 100%;
  background-position: right;
  color: #fff;
  transition: background 0.5s ease;
  border-radius: 0;
  text-transform: uppercase;
  line-height: 1.5;
  text-align: center;
  outline: center;

  text-decoration: none;
  font-family: "FF Clan Pro", sans-serif;
}

.btn__default:hover {
  background-position: left;
  color: #fff;
}

.d-mobile {
  display: none;
}

.preco-normal {
    color: #888;
    text-decoration: line-through;
  }

  .preco-oferta {
    color: #d32f2f;
  }


  .h6 {
    font-size: 12px;
  }

  .info__values .value {
    font-size: 25px;
  }

  .preco-normal,
  .preco-oferta {
    font-size: 24px;
  }

  .value_total .value {
    font-size: 24px;
  }

  .value_total {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .btn__default {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }



@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* .hidde__default {
      display: none;
    } */

  .d-desktops {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .info__values span {
    font-size: 22px;
  }

  .btn__default {
    width: 100%;
  }

  .preco-normal {
    color: #888;
    text-decoration: line-through;
  }

  .preco-oferta {
    color: #d32f2f;
  }


  .h6 {
    font-size: 12px;
  }

  .info__values .value {
    font-size: 25px;
  }

  .preco-normal,
  .preco-oferta {
    font-size: 22px !important;
  }

  .value_total .value {
    font-size: 26px;
  }

  .value_total {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .btn__default {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info__values .value {
    font-size: 26px;
  }

  .btn__default {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .panel-menu {
      font-family: "Clan-Book Book", "FF Clan Pro" sans-serif;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 0px;
      background-color: #ffffff;
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.29);
      z-index: 9999;
      -webkit-transform: translateY(0px);
      transform: translateY(20px);
      opacity: 0;
      visibility: hidden;
      transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
      height: 90px;
    } */

  /* .panel-menu.is_visible {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    } */
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .hidde__default {
    display: none;
  }

  .d-desktops {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .info__values .value {
    font-size: 25px;
  }

  .btn__default {
    width: 100%;
  }

  .panel-menu {
    font-family: "Clan-Book Book", "FF Clan Pro" sans-serif;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0px;
    background-color: #ffffff;
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.29);
    z-index: 9999;
    -webkit-transform: translateY(0px);
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    height: 90px;
  }

  .preco-normal {
    color: #888;
    text-decoration: line-through;
  }

  .preco-oferta {
    color: #d32f2f;
  }


  .h6 {
    font-size: 14px;
  }

  .info__values .value {
    font-size: 25px;
  }

  .preco-normal,
  .preco-oferta {
    font-size: 26px !important;
  }

  .d-mobile .value_total .value {
    font-size: 28px;
  }

  .value_total {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .btn__default {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


@media only screen and (min-width: 576px) and (max-width: 768px) {
  .hidde__default {
    display: none;
  }

  .panel-menu {
    height: 100px;
  }

  .d-mobile {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
  }
  .d-desktops {
    width: 100%;
    display: block;
  }

  .preco-normal {
    color: #888;
    text-decoration: line-through;
  }

  .preco-oferta {
    color: #d32f2f;
  }


  .h6 {
    font-size: 14px;
  }


  .info__values .preco-normal span,
  .info__values .preco-oferta span {
    font-size: 26px !important;
  }

  .info__values .value_total .value {
    font-size: 28px;
  }

  .info__values .value_total {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .btn__default {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 576px) {
  .hidde__default {
    display: none;
  }

  .panel-menu {
    height: 135px;
    padding-bottom: 30px;
  }

  .d-mobile {
    display: block;
  }


  .btn__default {
    display: flex;
    align-items: center;
    width: 100%;
    height: 47.40px;
    font-size: 16px;
  }

  .d-desktops {
    width: 100%;
    display: block;
  }

  .preco-normal {
    color: #888;
    text-decoration: line-through;
  }

  .preco-oferta {
    color: #d32f2f;
  }


  .d-mobile h6 {
    font-size: 14px;
  }

  .d-mobile .info__values span {
    font-size: 22px;
  }

  .d-mobile .preco-normal,
  .preco-oferta {
    font-size: 16px !important;
  }

  .d-mobile .value_total .value {
    font-size: 26px;
  }

  .d-mobile .value_total {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

}
</style>